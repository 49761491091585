import { Link, useRouteLoaderData } from '@remix-run/react';
import type { ViewerSession } from '~/utils/auth.server';
import { cn } from '~/utils/cn';
import { Button } from './ui/Button';
import { HeadingXS, textVariants } from './ui/Typography';

export function SubscriptionBanner(props: Omit<React.ComponentProps<typeof Link>, 'to'>) {
  const data = useRouteLoaderData<{ viewer?: ViewerSession }>('root');

  if (data?.viewer?.currentSubscription?.productPrice?.price?.amount) return null;

  return (
    <Link
      {...props}
      to="/abonnements"
      className={cn('px-4 xl:px-0 block relative', props.className)}
      prefetch="intent"
    >
      <span className="animate-ping-slow absolute inline-flex rounded-xl bg-secondary h-full w-full" />

      <div className="p-4 relative rounded-xl flex-wrap md:justify-between gap-3 w-full bg-surface-yellow border-2 my-6 flex items-center hover:border-foreground transition-colors duration-300">
        <div className="flex flex-col">
          <HeadingXS>L'abonnement Colette est maintenant disponible !</HeadingXS>
          <p className={textVariants({ size: 'l', class: 'text-subdued-foreground font-medium' })}>
            Participez à toutes les activités Colette sans limite pour 14.99€/mois sans engagement.
          </p>
        </div>

        <Button type="button" className="mx-auto md:mx-0" size="m" variant="tertiary">
          Découvrir l'abonnement
        </Button>
      </div>
    </Link>
  );
}
